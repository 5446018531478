import React from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";import LykeFooter from "./LykeFooter";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import dlonie from "../assets/img/ofertadlonie.jpg";


function LykeOffer () {

  const [form, setForm] = React.useState({})


  const sendMessage = () => {
    axios.post("https://netluck.pl:9030/smartlyke/mail", form).then(() => {
      toast.success('Wiadomość wysłana');

      setForm({});

    }).catch(() => {
      toast.error('Wystąpił problem - prosimy spróbować później');
    })
  }

  const [nameFocus, setNameFocus] = React.useState(false);
  const [lastnameFocus, setLastameFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [positionFocus, setPositionFocus] = React.useState(false);
  const [companySizeFocus, setCompanySizeFocus] = React.useState(false);


  const formChange = (e) => {
    const {name, value} = e.target
    const tmp = {...form}
    tmp[name] = value
  setForm(tmp)
}


    return (
      <>
        <div className="pricing-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12" style={{marginTop: '110px'}}>
              <img className="offer-hands" src={dlonie} alt="zolte logo smartlyke"></img>
                <h4 className="description">
                Tworzymy rozwiązania dopasowane do potrzeb, z radością przedstawimy Państwu indywidualną ofertę. Zapraszamy do kontaktu.
                </h4>
                <div className="section-space"></div>
              </Col>
            </Row>
            <div className="">
        <div className="main">
          <div className="contact-content">
            <Container>
            <Col className="ml-auto mr-auto" md="12" style={{display:'flex', flexWrap: 'wrap'}}>
                          <div className="info info-contact info-horizontal">
                    <div className="icon icon-orange">
                      <i className="now-ui-icons location_pin"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Franklina Roosevelta 22, 60-829 Poznań <br /></h4>
                    </div>
                    </div>
                  <div className="info info-contact info-horizontal">
                    <div className="icon icon-orange">
                      <i className="now-ui-icons tech_mobile"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">61 898 78 98</h4>
                    </div>
                    </div>
                    <div className="info info-contact info-horizontal">
                    <div className="icon icon-orange">
                      <i className="now-ui-icons ui-1_email-85"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">biurko@edulyke.pl</h4>
                    </div>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="8">
                <Card className="card-contact card-raised" style={{borderRadius: "25px", paddingLeft: "20px", paddingRight: "20px", marginTop: '50px'}}>
                <h2 className="title title-contact" style={{textAlign: 'center'}}><span style={{color:"#FEBA02", fontWeight: "bold"}}>SKONTAKTUJ</span> SIĘ</h2>
                  <p className="description">
                    Zainteresowaliśmy Cię? Masz pytania dotyczące <span style={{color: "#FEBA02", fontWeight: "bold"}}>SmartLyke</span>? 
                    Wybierz najwygodniejszy dla siebie sposób kontaktu i daj nam
                    znać jak możemy Ci pomóc.<br></br>
                    <br></br>
                  </p>
                  <Form id="contact-form" method="post" role="form">
                    <label>Imię</label>
                    <InputGroup
                      className={nameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons users_circle-08"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="name"
                        placeholder="Imię..."
                        type="text"
                        required
                        onChange={formChange}
                        onFocus={(e) => setNameFocus(true)}
                        onBlur={(e) => setNameFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Nazwisko</label>
                    <InputGroup
                      className={lastnameFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons text_caps-small"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="lastname"
                        placeholder="Nazwisko..."
                        type="text"
                        onChange={formChange}
                        onFocus={(e) => setLastameFocus(true)}
                        onBlur={(e) => setLastameFocus(false)}
                        required
                      ></Input>
                    </InputGroup>
                    <label>Adres e-mail</label>
                    <InputGroup
                      className={emailFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons ui-1_email-85"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="email"
                        placeholder="Email..."
                        type="email"
                        onChange={formChange}
                        required
                        onFocus={(e) => setEmailFocus(true)}
                        onBlur={(e) => setEmailFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Stanowisko</label>
                    <InputGroup
                      className={positionFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons business_badge"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="position"
                        placeholder="Stanowisko..."
                        type="text"
                        onChange={formChange}
                        required
                        onFocus={(e) => setPositionFocus(true)}
                        onBlur={(e) => setPositionFocus(false)}
                      ></Input>
                    </InputGroup>
                    <label>Wielkość organizacji</label>
                    <InputGroup
                      className={companySizeFocus ? "input-group-focus" : ""}
                    >
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="now-ui-icons location_world"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="companySize"
                        placeholder="Liczba osób pracujących w firmie"
                        type="number"
                        onChange={formChange}
                        required
                        onFocus={(e) => setCompanySizeFocus(true)}
                        onBlur={(e) => setCompanySizeFocus(false)}
                      ></Input>
                    </InputGroup>
                    <FormGroup>
                      <label>Wiadomość</label>
                      <Input
                        id="message"
                        name="message"
                        placeholder="Twoja wiadomość..."
                        rows="6"
                        type="textarea"
                        onChange={formChange}
                        required
                      ></Input>
                    </FormGroup>
                    <div className="submit text-center">
                      <Button
                        className="btn-raised btn-round contactBtn"
                        style={{backgroundColor: "#FEBA02", fontWeight: "bold"}}
                        defaultValue="Contact Us"
                        onClick={sendMessage}
                      >
                        Wyślij
                      </Button>
                    </div>
                  </Form>
                  </Card>
                </Col>
            </Container>
          </div>
        </div>
        <Toaster/>
      </div>
          </Container>
        </div>
        <LykeFooter />
      </>
    );
  }

export default LykeOffer;
