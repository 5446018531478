import React from "react";
import Landing from "./landing";
import Business from "./b2b";
import Program from "./program";
import Whyimportant from "./whyimportant";
import ThreeParts from "./threeParts";
import ThreeIcons from "./threeIcons";
import Footer from "./footer";
import LastPage from "./lastPage";
import TablePage from "./offer";
import LykeFooter from "components/LykeFooter";


function BusinessMain() {
    return (
        <>
        <Landing />
            <Business />
            <Whyimportant />
            <Program />
            <ThreeParts />
            <ThreeIcons />
            <TablePage />
            <LastPage />
            <Footer />
        </>
    );
}

export default BusinessMain;
