import {Button, Col, Container, Row} from "reactstrap";
import React from "react";

const Business = () => {
    return (
        <div style={{height: '100vh', backgroundColor: '#17181a', display: 'flex', justifyContent: 'center'}}>

<div className={'box'}>
    <div className={'boxik'}><h2 className="b2bProgram">EDULYKE DLA BIZNESU TO <span style={{color: '#ed5d01'}}>PROGRAM WELLBEINGOWY</span> DLA PRACOWNIKÓW DUŻYCH I MAŁYCH FIRM WSZYSTKICH BRANŻ, W KTÓRYCH ZESPÓŁ POTRZEBUJE SKUTECZNEGO WSPARCIA I MOTYWACJI.</h2></div>
</div>
        </div>
    )
}

export default Business;