import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
} from "reactstrap";

function LykeTutor() {
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  return (
    <>
      <div className="faqCollapse">
        <div id="collapse" className="faqLyke">
          <Row>
            <Col md="12">
              <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
              >
                <Card className="card-plain">
                  <CardHeader id="headingOne" role="tab">
                    <a
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      aria-expanded={collapses.includes(1)}
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(1);
                      }}
                    >
                      1. Mowa nienawiści w szkole
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    </a>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(1)}>
                    <CardBody>
                      <p className="hiw-faq-part-info" style={{lineHeight: "1.7em"}}>
                        Kurs <span className="hiw-faq-part-title">
                          Mowa nienawiści w szkole </span>
                        dedykowany jest osobom pracującym z dziećmi, w
                        szczególności nauczycielom. Jego treść dotyczy różnych
                        form hejtu, z którymi mierzy się nauczyciel w środowisku
                        szkolnym. Dotyczy to mowy nienawiści w relacjach:
                        <br />
                        uczeń - nauczyciel
                        <br />
                        uczeń - uczeń
                        <br />
                        rodzic - nauczyciel
                        <br />
                        przełożony - nauczyciel
                        <br />
                      </p>
                      <p
                        className="hiw-faq-part-info"
                        style={{ paddingTop: "10px" }}
                      >
                        <span
                          className="hiw-faq-part-title"
                          style={{ fontSize: "1.1em" }}
                        >
                          Tematy poruszone w kursie:
                        </span>
                        <br /><br />
                        1. <span className="hiw-faq-part-title">
                          MOWA NIENAWIŚCI </span>
                        <br />
                        - rodzaje mowy nienawiści
                        <br />
                        2. <span className="hiw-faq-part-title">
                          MOWA NIENAWIŚCI: NAUCZYCIEL - UCZEŃ </span>
                        <br />
                        - powody agresji ze strony ucznia
                        <br />
                        - reagowanie na agresję ze strony ucznia
                        <br />
                        3. <span className="hiw-faq-part-title">
                          MOWA NIENAWIŚCI: UCZEŃ - UCZEŃ </span>
                        <br />
                        - sygnały świadczące o występowaniu hejtu
                        <br />
                        - rola nauczyciela w sytuacji przemocowej
                        <br />
                        4. <span className="hiw-faq-part-title">
                          UCZEŃ: AUTOR HEJTU </span>
                        <br />
                        - rozmowa z uczniem sprawcą hejtu
                        <br />
                        - rozmowa z uczniem doświadczającym hejtu
                        <br />
                        - psychologiczne motywy i korzyści osoby stosującej hejt
                        <br />
                        5. <span className="hiw-faq-part-title">
                          MOWA NIENAWIŚCI PŁYNĄCA Z GÓRY </span>
                        <br />
                        - mobbing
                        <br />
                        6. <span className="hiw-faq-part-title">
                          HEJT ZE STRONY RODZICÓW  </span><br />
                        - jak reagować?
                        <br />
                        7. <span className="hiw-faq-part-title">
                          ZAPOBIEGANIE HEJTOWI </span>
                        <br />- wskazówki dobrych praktyk w środowisku szkolnym
                      </p>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader id="headingTwo" role="tab">
                    <a
                      aria-expanded={collapses.includes(2)}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(2);
                      }}
                    >
                      2. Emocje w klasie i grupie
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    </a>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(2)}>
                    <CardBody>
                      
                      <p className="hiw-faq-part-info" style={{lineHeight: "1.7em"}}>
                        Kurs <span className="hiw-faq-part-title">
                          Emocje w klasie i grupie
                        </span> przeznaczony jest dla osób pracujących z dziećmi,
                        szczególnie dla nauczycieli uczniów szkoły podstawowej.
                        Pierwsza część traktuje o emocjach i jest uniwersalnym
                        wstępem dotyczącym emocji, zarówno tych nauczyciela, jak
                        i ucznia. Następnie proponujemy treść wspierającą
                        nauczyciela w pracy z dziećmi, przedstawiając wiedzę na
                        temat inteligencji emocjonalnej dzieci, kształtowania
                        jej, radzenia sobie z emocjami w grupie szkolnej.
                        <br />
                        <br />
                        <span
                          className="hiw-faq-part-title"
                          style={{ fontSize: "1.1em" }}
                        >
                          Tematy poruszone w kursie:
                        </span>
                        <br /><br />
                        1. <span className="hiw-faq-part-title">EMOCJE</span>
                        <br />
                        - typy, funkcje, przykłady
                        <br />
                        - ważne informacje o emocjach
                        <br />
                        2. <span className="hiw-faq-part-title">
                          TRUDNE EMOCJE
                        </span>
                        <br />
                        - gniew, złość, strach, lęk
                        <br />
                        3. <span className="hiw-faq-part-title">
                          EMOCJE W GRUPIE, EMOCJE W SZKOLE
                        </span>
                        <br />
                        - przyczyny trudności wychowawczych na tle emocjonalnym
                        <br />
                        - inteligencja emocjonalna
                        <br />
                        4. <span className="hiw-faq-part-title">
                          Reagowanie na przejawy dużych emocji w klasie
                        </span>
                        <br />
                        - asertywność, skupienie i medytacja, ekspresja
                        artystyczna, dialog, relaks
                        <br />
                        - umiejętności regulowania emocji
                        <br />
                        5. <span className="hiw-faq-part-title">
                          PROPOZYCJE ZABAW NA ROZŁADOWANIE EMOCJI                        </span>
                        <br />
                        6. <span className="hiw-faq-part-title">
                          WSKAZÓWKI DLA NAUCZYCIELA (DOBRE PRAKTYKI)
                        </span>
                        <br />
                      </p>
                      <hr />
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader id="headingThree" role="tab">
                    <a
                      aria-expanded={collapses.includes(3)}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(3);
                      }}
                    >
                      3. Uzależnienia XXI wieku
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    </a>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(3)}>
                    <CardBody>
                    <p className="hiw-faq-part-info" style={{lineHeight: "1.7em"}}>
                        Kurs <span className="hiw-faq-part-title">
                          Uzależnienia XXI wieku
                        </span> przeznaczony jest dla osób pracujących z dziećmi i
                        młodzieżą. Jego treść skierowana jest do osób dorosłych
                        i dotyczy wybranych trudności behawioralnych młodych
                        osób w wieku szkolnym. Formuła kursu opiera się na
                        budowie:
                        <br />
                        - Czym jest
                        <br />
                        - Symptomy ostrzegawcze
                        <br />
                        - Reagowanie
                        <br />
                        - Zapobieganie
                        <br />
                        <br />
                        <span
                          className="hiw-faq-part-title"
                          style={{ fontSize: "1.1em" }}
                        >
                          Tematy poruszone w kursie:
                        </span>
                        <br /><br />
                        1. <span className="hiw-faq-part-title">
                          Uzależnienie behawioralne:
                        </span> <br />
                        - Czym jest uzależnienie behawioralne?
                        <br />
                        - Rodzaje uzależnień behawioralnych
                        <br />
                        - Symptomy ostrzegawcze
                        <br />
                        - Reagowanie na przejawy uzależnienia behawioralnego
                        <br />
                        - Zapobieganie uzależnieniom behawioralnym
                        <br />
                        2. <span className="hiw-faq-part-title">
                          Pozamedyczne przyjmowanie leków i środków medycznych
                        </span> <br />
                        - Jakie środki odurzające zażywa młodzież? Dlaczego?
                        <br />
                        - Symptomy ostrzegawcze
                        <br />
                        - Reagowanie na przejawy pozamedycznego przyjmowania
                        leków i środków medycznych
                        <br />
                        - Zapobieganie pozamedycznego przyjmowania leków
                        <br />
                        3. <span className="hiw-faq-part-title">
                          Uzależnienie od e-papierosów
                        </span> <br />
                        - Urządzenia elektryczne jako zamienniki papierosów
                        <br />
                        - Rodzaje e-papierosów
                        <br />
                        - Szkodliwość używania e-papierosów
                        <br />
                        - Symptomy ostrzegawcze
                        <br />
                        - Reagowanie na używanie e-papierosów
                        <br />
                        - Zapobieganie używania e-papierosów
                        <br />
                      </p>
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default LykeTutor;
