import {Button, Col, Container, Row} from "reactstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import "./table.css";
import React from "react";


const TablePage = () => {
    return (
        <>
            <div style={{ backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}>
                <div id='offer' style={{marginTop: '50px'}}><h1 style={{textAlign: 'center'}}>2 MODELE OFERTY</h1>
                    <div className='offerImages'>
                    <div className="info info-offer">
                                <div><img className="offerImage" src={require("assets/img/table1.png")}/></div>
                                <h4 className="info-title offerTitle">ABONAMENT NA<br /> 12 MIESIĘCY</h4>
                            </div>
                            <div className="info info-offer">
                                <div><img className='offerImage' src={require("assets/img/table2.png")}/></div>
                                <h4 className="info-title offerTitle">JEDNORAZOWA<br /> PŁATNOŚĆ Z GÓRY</h4>
                            </div>

                    </div>
                        <div id='specyfikacja'>
                <h1 style={{textAlign: 'center', marginTop: '40px', marginBottom: '40px'}} className={'specification'}>SPECYFIKACJA</h1>
                    <Table className="orangeTable" style={{marginBottom: '80px'
                    }}>
                        <Thead>
                            <Tr><Th></Th></Tr>
                        </Thead>
                        <Thead>
                            <Tr >
                                <Th style={{fontWeight: 'bold'}}>LICZBA PRACOWNIKÓW</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>do 10</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>10-25</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>25-50</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>50-100</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>100-500</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>500-1000</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>1000-2000</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>2000-5000</Th>
                                <Th style={{fontWeight: 'bold'}} className={'headers'}>5000+</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            <Tr>
                                <Td>1 pracownik / miesiąc</Td>
                                <Td>41,67 zł</Td>
                                <Td>37,50 zł</Td>
                                <Td>35,42 zł</Td>
                                <Td>33,33 zł</Td>
                                <Td>30 zł</Td>
                                <Td>25 zł</Td>
                                <Td>22 zł</Td>
                                <Td>20 zł</Td>
                                <Td>OFERTA INDYWIDUALNA</Td>
                            </Tr>
                            <Tr>
                                <Td>max pracowników / miesiąc</Td>
                                <Td>416,70 zł</Td>
                                <Td>937,50 zł</Td>
                                <Td>1 771 zł</Td>
                                <Td>3 333 zł</Td>
                                <Td>15 000 zł</Td>
                                <Td>25 000 zł</Td>
                                <Td>44 000 zł</Td>
                                <Td>100 000 zł</Td>
                                <Td>OFERTA INDYWIDUALNA</Td>
                            </Tr>
                            <Tr>
                                <Td>max pracowników / rok</Td>
                                <Td>5 000 zł</Td>
                                <Td>11 250 zł</Td>
                                <Td>21 250 zł</Td>
                                <Td>40 000 zł</Td>
                                <Td>180 000 zł</Td>
                                <Td>300 000 zł</Td>
                                <Td>528 000 zł</Td>
                                <Td>1000 000 zł</Td>
                                <Td>OFERTA INDYWIDUALNA</Td>
                            </Tr>
                        </Tbody>
                    </Table>
                    </div>
                    </div>
                </div>
        </>
    )
}

export default TablePage;