import React from "react";
import {
  Container
} from "reactstrap";

const LykeHeader = () => {
  return (
    <>
             
        <div className="page-header header-filter" style={{
              backgroundImage:
                "url(" + require("assets/img/smartlyke.jpg") + ")",
                backgroundSize: "cover",
            backgroundPosition: "top",
            marginTop: "50px",
            backgroundPositionY: '7%',
            height: '73vh',
            }} >
            <Container>
                <span className="headerTitle2">Smart<b>LYKE</b></span>
            </Container>
        </div>    </>
  );
};

export default LykeHeader;
