/*!

=========================================================
* Now UI Kit PRO React - v1.5.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2022 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
import "video-react/dist/video-react.css";
// pages
import LykeAboutUs from "components/LykeAboutUs";
import CustomersPage from "components/CustomersPage";
import LykeOffer from "components/LykeOffer";
import Main from "components/Main";
import LykeWorld from "components/LykeWorld";
import LykeContact from "components/LykeContact";
import ScrollToTop from "components/ScrollToTop";
import InvalidUrl from "components/InvalidUrl";
import OfferEducation from "components/OfferEducation";
import OfferBusiness from "components/OfferBusiness";
import BusinessMain from "components/biznes/businessMain";
import Login from "components/Login";
// others

const root = ReactDOM.createRoot(document.getElementById("root"));


root.render(
  <BrowserRouter>
    <ScrollToTop>
    <Switch>
      <Route path="/o-nas" render={(props) => <LykeAboutUs {...props} />} />
      <Route path="/swiat-lyke" render={(props) => <LykeWorld {...props} />} />
      <Route path="/kontakt" render={(props) => <LykeContact {...props} />} />
      <Route path="/oferta/edukacja" render={(props) => <OfferEducation {...props} />} />
      <Route path="/oferta/biznes" render={(props) => <OfferBusiness {...props} />} />
      <Route path="/oferta" render={(props) => <LykeOffer {...props} />} />
      <Route path="/klienci" render={(props) => <CustomersPage {...props} />} />
      <Route path="/biznes" render={(props) => <BusinessMain {...props} />} />
      <Route path="/logowanie" render={(props) => <Login {...props} />} />      
      <Route exact path="/" render={(props) => <Main {...props} />} />
      <Route path="*" component={InvalidUrl} />
            </Switch>
    </ScrollToTop>
  </BrowserRouter>
);

