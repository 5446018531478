import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import {
  Badge,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

const LykeSection = () => {
  return (
    <div className="about-description text-center">
      <div className="features-3">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="12">
              <h2 className="title" style={{ marginTop: "45px" }}>
                <span style={{ color: "#FEBA02", fontWeight: "bold" }}>
                  SMARTLYKE
                </span>
                &nbsp;to platforma e-learningowa oferująca kursy rozwojowe.
              </h2>
              <h4 className="description" style={{ marginBottom: "40px" }}>
                W ramach naszej platformy dostępne są warsztaty dotyczące kompetencji społecznych, profilaktyki zdrowia psychicznego, komunikacji, budowania kompetencji psychospołecznych oraz wychowawczych. Nasze kursy są opracowywane przez zespoły ekspertów z dziedzin takich jak psychologia, pedagogika, biznes i well-being.
              </h4>
            </Col>
          </Row>
          </Container>
          <Container style={{marginTop: "40px", marginBottom: "40px"}}>
              <Row style={{flexWrap: 'wrap-reverse'}}>
                <Col className="ml-auto mt-5" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </div>
                    <div className="description">
                      <p className="description">
                      6 mld zł co roku mogłyby zaoszczędzić polskie firmy, gdyby zapewniały wsparcie psychiczne pracownikom
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </div>
                    <div className="description">
                      <p className="description">
                      10,5 % zwolnień chorobowych ma podstawę w zaburzeniach psychicznych i zaburzeniach zachowania
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </div>
                    <div className="description">
                      <p className="description">
                      Koszty odejścia pracownika z firmy to 33% jego rocznego wynagrodzenia.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-warning">
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </div>
                    <div className="description">
                      <p className="description">
                      Od 2022 wypalenie zawodowe jest wpisane na listę chorób i może być podstawą do L4.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col className="mr-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/project9.jpg") + ")",
                        height: '100%',
                        backgroundPosition: '10%'
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">DLACZEGO TEN PROGRAM JEST WAŻNY?</CardTitle>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          <p style={{marginTop: '80px'}}>Zapraszamy do skorzystania z naszej <Link className={'hiperLyke'} to={'/oferta'}>platformy SMARTLYKE</Link>, gdzie znajdziecie szeroki wybór wartościowych kursów,<br /> które pomogą w rozwoju osobistym i zawodowym.</p>
      </div>
    </div>
  );
};

export default LykeSection;
