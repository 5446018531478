import React from "react";

import { Container, Row, Col } from "reactstrap";
import { Player, BigPlayButton } from "video-react";
import LykeFooter from "./LykeFooter";
import LykeOpinion from "./LykeOpinion";


const LykeAboutUs = () => {

  return (
    <>
        <div className="features-5" style={{display: 'flex'}}>
          <Container>
            <Row style={{marginTop: '110px'}}>
              <h2 className="title" style={{paddingLeft: '10px', paddingRight: '10px', fontSize: '2.3em'}}><span style={{color: "#febb00"}}>SmartLyke</span> to program wieloobszarowego wsparcia dla dorosłych</h2>
              <Col md="6" >
                <div className="info info-horizontal">
                  <div className="icon icon-orange icon-circle" style={{marginRight: '15px'}}>
                    <i className="now-ui-icons location_world"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">FORMA PROGRAMU</h4>
                    <p>
                    Program ma formę wygodnego kursu na platformie e-learningowej. Składa się z 20 tematów ułożonych w 3 części (Zdrowie psychiczne, Komunikacja i Aktywność fizyczna), poruszając kompleksowo najważniejsze kwestie od motywacji po higienę snu.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-orange icon-circle" style={{marginRight: '15px'}}>
                    <i className="now-ui-icons sport_user-run"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">E-LEARNING</h4>
                    <p>
                    Kurs znajduje się na autorskiej, bezpiecznej platformie edukacyjnej. Formuła kursów oferuje zróżnicowane treści i angażujące formy, dzięki którym materiał jest ciekawy i przyjemny w odbiorze. Są to m. in.: video, quizy, grafiki i pliki do pobrania do dalszej pracy. 
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="6" style={{display: 'flex', alignItems:'center'}}>
                <div className="tablet-container" >
                  <img
                    alt="zrzut ekranu kursow lyke"
                    src={require("assets/img/mockup-smart.png")}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      <LykeFooter />
    </>
  )
}

export default LykeAboutUs;