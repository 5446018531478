import {
  Badge,
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
} from "reactstrap";
import React from "react";

const Program = () => {
  return (
    <div
      className="projects-5"
      style={{
        backgroundImage: "url(" + require("assets/img/ekran4.jpg") + ")",
        backgroundPosition: "center",
        backgroundSize: "cover",
        position: "relative",
        minHeight: "100vh",
      }}
    >
      <div className={"bg-overlay"}></div>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <div style={{ display: "flex" }} className="programBusiness" id='programForm'>
            <div>
              <h1
                style={{
                  borderRadius: "10px",
                  color: "#ed5d01",
                  textAlign: "center",
                  backgroundColor: "#000814",
                  padding: "10px",
                  marginTop: "20px",
                }}
              >
                FORMA PROGRAMU
              </h1>
              <div className="cardBox">
                <div className="cardProgram">
                  <span>
                    Program ma formę wygodnego kursu na platformie
                    e-learningowej. Składa się z 20 tematów ułożonych w 3
                    części, poruszając kompleksowo najważniejsze kwestie od
                    motywacji po higienę snu. <br /> <br /> Części programu
                    udostępniane są pracownikom systematycznie, moduł po module,
                    przez 12 miesięcy, co zwiększa zaangażowanie i skupienie na
                    przyswajanej treści.
                  </span>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div
            className="programBusiness programAlign"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div>
              <h1
                style={{
                  borderRadius: "10px",
                  color: "#ed5d01",
                  textAlign: "center",
                  backgroundColor: "#000814",
                  padding: "10px",
                }}
              >
                E - LEARNING
              </h1>
              <div className="cardBox">
                <div className="cardProgram">
                  <span>
                    Kurs znajduje się na autorskiej, bezpiecznej platformie
                    edukacyjnej. Formuła kursów oferuje zróżnicowane treści i
                    angażujące formy, dzięki którym materiał jest ciekawy i
                    przyjemny w odbiorze. Są to m. in.: video, quizy, grafiki i
                    pliki do pobrania do dalszej pracy.{" "}
                  </span>
                  <div className={"programIcons"}>
                    <img src={require("assets/img/ikony.png")} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Program;
