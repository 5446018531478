import { Button, Col, Container, Row } from "reactstrap";
import React from "react";

document.getElementsByClassName("cards").onmousemove = (e) => {
  for (const card of document.getElementsByClassName("orangeCard")) {
    const rect = card.getBoundingClientRect(),
      x = e.clientX - rect.left,
      y = e.clientY - rect.top;

    card.style.setProperty("--mouse-x", `${x}px`);
    card.style.setProperty("--mouse-y", `${y}px`);
  }
};

const ThreeIcons = () => {
  return (
    <>
      <div
        style={{
          minHeight: "100vh",
          backgroundColor: "#17181a",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
        }}
      >
        <div className="boxContainer">
          <div className="cardBoxIcons glow">
            <span></span>
            <div className="cardProgramIcons">
              <div className={"icon-program"}>
                <img src={require("assets/img/ikon1.png")} />
              </div>
              <h2>SKUTECZNOŚĆ</h2>
              <p>
                materiał teoretyczny utrwalany jest poprzez ćwiczenia, a program
                ułożony jest przez ekspertów z zakresu zdrowia.
              </p>
            </div>
          </div>
          <div className="cardBoxIcons glow2">
            <span></span>
            <div className="cardProgramIcons">
              <div className={"icon-program"}>
                <img src={require("assets/img/ikon3.png")} />
              </div>
              <h2>EWALUACJA</h2>
              <p>
                wszystkie moduły zawierają testy wewnętrzne, dzięki czemu
                uczestnik może sprawdzić stan swojej wiedzy.
              </p>
            </div>
          </div>
          <div className="cardBoxIcons glow3">
            <span></span>
            <div className="cardProgramIcons">
              <div className={"icon-program"}>
                <img src={require("assets/img/ikon2.png")} />
              </div>
              <h2>DOSTĘPNOŚĆ</h2>
              <p>
                forma e-learningu pozwala na dostęp z dowolnego miejsca i w
                dowolnym czasie, również cofanie się do minionych kursów.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThreeIcons;
