import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Row,
  Col,
} from "reactstrap";

function LykeAdults() {
  const [collapses, setCollapses] = React.useState([0]);
  const changeCollapse = (collapse) => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter((prop) => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  return (
    <>
      <div className="faqCollapse">
        <div id="collapse" className="faqLyke">
          <Row>
            <Col md="12">
              <div
                aria-multiselectable={true}
                className="card-collapse"
                id="accordion"
                role="tablist"
              >
                <Card className="card-plain">
                  <CardHeader id="headingOne" role="tab">
                    <a
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      aria-expanded={collapses.includes(1)}
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(1);
                      }}
                    >
                      1. Warsztaty psychospołeczne
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    </a>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(1)}>
                    <CardBody>
                    <p className="hiw-faq-part-info" style={{lineHeight: "1.7em"}}>
                            Moduł <span className="hiw-faq-part-title">
                              Warsztaty psychospołeczne
                            </span> dedykowany jest przede wszystkim rodzicom i
                            opiekunom dzieci w wieku przedszkolnym i
                            wczesnoszkolnym, odnosi się też do rodziców i
                            opiekunów dzieci w wieku nastoletnim. Składa się z
                            bardzo przystępnej wiedzy na temat podstaw
                            komunikacji z dzieckiem oraz rozwijania u dzieci
                            kluczowych umiejętności, takich jak asertywność,
                            radzenie sobie ze stresem i lękiem, emocjami,
                            wyrażanie potrzeb.
                            <br /> <br />
                            <span className="hiw-faq-part-title" style={{fontSize: "1.1em"}}>
                              Tematy poruszone w kursie:
                            </span>
                            <br /> <br />
                            1. <span className="hiw-faq-part-title">
                              JAK NAUCZYĆ DZIECKO MĄDREJ ASERTYWNOŚCI?
                            </span> <br />
                            - Ważne wskazówki dla rodzica/opiekuna przy nauce
                            asertywności
                            <br />
                            - Sprawdzone sposoby na uczenie dziecka asertywności
                            <br />
                            - Asertywna odmowa
                            <br />
                            - Przydatne zwroty w odmowie asertywnej
                            <br />
                            2. <span className="hiw-faq-part-title">
                              RADZENIE SOBIE ZE STRESEM I LĘKIEM
                            </span> <br />
                            - Pomocne metody radzenia sobie ze strachem i lękiem
                            <br />
                            - Komunikaty, których rodzic powinien używać -
                            przykłady
                            <br />
                            - Zachowania zabronione przy pojawieniu się
                            stresu/lęku u dziecka
                            <br />
                            - Rola rodzica w przezwyciężanie lęku u dziecka
                            <br />
                            3. <span className="hiw-faq-part-title">
                              RADZENIE SOBIE Z EMOCJAMI
                            </span> <br />
                            - Ważne wskazówki w radzeniu sobie z emocjami
                            <br />
                            - Techniki radzenia sobie z emocjami
                            <br />
                            - Zabawy wspierające regulację emocji
                            <br />
                            4. <span className="hiw-faq-part-title">
                              WYRAŻANIE POTRZEB
                            </span> <br />
                            - Rodzaje potrzeb u dzieci
                            <br />
                            - Charakterystyka potrzeb
                            <br />
                            - Sposoby na wyrażanie potrzeb
                            <br />
                            - Zabawy wspomagające wyrażenie własnych potrzeb i
                            emocji
                            <br />
                            - Zabawy wspierające poczucie własnej wartości
                            <br />
                            5. <span className="hiw-faq-part-title">
                              ROZMOWA Z DZIECKIEM
                            </span> <br />
                            - Zasady komunikacji z dzieckiem
                            <br />
                            - Potrzeby komunikacyjne dziecka z podziałem na wiek
                            <br />
                            - Praktyczne porady w rozmowie z dzieckiem
                            <br />
                          </p>
                    </CardBody>
                  </Collapse>
                </Card>
                <Card className="card-plain">
                  <CardHeader id="headingTwo" role="tab">
                    <a
                      aria-expanded={collapses.includes(2)}
                      style={{ fontSize: "20px", cursor: "pointer" }}
                      data-toggle="collapse"
                      onClick={(e) => {
                        e.preventDefault();
                        changeCollapse(2);
                      }}
                    >
                      2. Uzależnienia dzieci i młodzieży
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    </a>
                  </CardHeader>
                  <Collapse isOpen={collapses.includes(2)}>
                    <CardBody>
                      
                      <p className="hiw-faq-part-info" style={{lineHeight: "1.7em"}}>
                            Moduł <span className="hiw-faq-part-title">
                              Uzależnienia dzieci i młodzieży
                            </span> skierowany jest do osób dorosłych, które wychowują
                            dzieci. Treść kursu może być szczególnie
                            interesująca dla rodziców i opiekunów dzieci
                            szkolnych w klasach 4-8 oraz w pierwszych etapach
                            edukacji ponadpodstawowej. Kurs w przystępny sposób
                            porusza tematy popularnych uzależnień wśród dzieci i
                            młodzieży i przedstawia sposoby radzenia sobie z
                            nimi, ale i zapobiegania wystąpieniu takim
                            sytuacjom.
                            <br /> <br />
                            <span className="hiw-faq-part-title" style={{fontSize: "1.1em"}}>
                              Tematy poruszone w kursie:
                            </span>
                            <br /> <br />
                            1. <span className="hiw-faq-part-title">
                              UZALEŻNIENIE OD ALKOHOLU
                            </span> <br />
                            - Przyczyny sięgania po alkohol u nastolatków
                            <br />
                            - Oddziaływanie alkoholu na zdrowie
                            <br />
                            - Jak rozmawiać z dzieckiem o alkoholu?
                            <br />
                            - Przykładowe argumenty w rozmowie z dzieckiem
                            <br />
                            2. <span className="hiw-faq-part-title">
                              UZALEŻNIENIE OD NARKOTYKÓW
                            </span> <br />
                            - Okoliczności, które mogą sprzyjać temu, aby
                            nastolatek sięgnął po narkotyki <br />
                            - Objawy narkomanii <br />
                            - Zachowania osoby uzależnionej <br />
                            - Jak rozmawiać z dzieckiem o uzależnieniu od
                            narkotyków? <br />
                            - Slang “narkotykowy” nastolatków <br />
                            - Gdzie szukać pomocy? <br />
                            3. <span className="hiw-faq-part-title">
                              UZALEŻNIENIA BEHAWIORALNE
                            </span> <br />
                            - Wybrane rodzaje uzależnień behawioralnych <br />
                            - Skala problemu <br />
                            - Hazard - sygnały ostrzegawcze <br />
                            - Internet, komputer - sygnały ostrzegawcze <br />
                            - Jedzenioholizm - sygnały ostrzegawcze <br />
                            - Zakupoholizm - sygnały ostrzegawcze <br />
                            - Fonoholizm - sygnały ostrzegawcze <br />
                            - Bigoreksja - sygnały ostrzegawcze <br />
                            4. <span className="hiw-faq-part-title">
                              UZALEŻNIENIE OD LEKÓW
                            </span> <br />
                            - Uzależnienie od leków – jak rozpoznać i leczyć
                            lekomanię?
                            <br />
                            - Kogo dotyczy uzależnienie od nadużywania leków
                            <br />
                            - Przyczyny nadużywania leków
                            <br />
                            - Sygnały ostrzegawcze u dziecka
                            <br />
                            - Skutki lekomanii
                            <br />
                            - Zapobieganie lekomanii
                            <br />
                            - Jak rozmawiać z dzieckiem, nastolatkiem?
                            <br />
                            5. <span className="hiw-faq-part-title">
                              UZALEŻNIENIE OD E-PAPIEROSÓW
                            </span> <br />
                            - skutki wprowadzania nikotyny do organizmu <br />
                            - rodzaje e-papierosów <br />
                            - szkodliwość e-papierosów <br />
                            - objawy uzależnienia od e-papierosów <br />
                            - profilaktyka uzależnień <br />
                          </p>
                      <hr />
                    </CardBody>
                  </Collapse>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default LykeAdults;
