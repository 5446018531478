import React from "react";
import { Link } from "react-router-dom";
import LykeFooter from "./LykeFooter";
import "../assets/css/LykeOffer.css"

import ParentIcon from "../assets/img/parent.png";
import TutorIcon from "../assets/img/tutor.png";
import StudentIcon from "../assets/img/student.png";
import LykeAdults from "./LykeAdults";
import LykeTutor from "./LykeTutor";

class Login extends React.Component {

  render() {
    return (
      <>
        <div
          className="section functions-padding how-it-works"
          style={{ marginTop: "50px" }}
        >
          <div className="container">
            <div className="row">
              <h2
                style={{
                  margin: "5px auto",
                  fontFamily: "Montserrat",
                  fontSize: "2.5em",
                  fontWeight: "400",
                }}
              >
                WYBIERZ <span style={{ color: "rgb(233, 72, 0)", fontWeight: "bold" }}>PLATFORMĘ </span>
              </h2>
            </div>
            <div>
              <div class="card-container">
                <div class="cardLogin">
                  <div class="box">
                    <div class="content">
                      <h3>EDULYKE DLA EDUKACJI</h3>
      
                      <a className="hiperLyke" href="https://system.edulyke.pl">
                <span>ZALOGUJ</span> 
                </a>
                    </div>
                  </div>
                </div>
                <div class="cardLogin">
                  <div class="box">
                    <div class="content">
                      <h3>EDULYKE DLA BIZNESU</h3>
                      <a className="hiperLyke" href="https://biznes.edulyke.pl">
                <span>ZALOGUJ</span> 
                </a></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LykeFooter />
      </>
    );
  }
}

export default Login;
