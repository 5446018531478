import React from "react";
import { Link } from "react-router-dom";
import LykeFooter from "./LykeFooter";
import "../assets/css/LykeOffer.css";

class OfferBusiness extends React.Component {
  render() {
    return (
      <>
        <div
          className="section functions-padding how-it-works"
          style={{ marginTop: "50px" }}
        >
          <div className="container">
            <div className="row">
              <h2
                style={{
                  margin: "5px auto",
                  fontFamily: "Montserrat",
                  fontSize: "2.5em",
                  fontWeight: "400",
                }}
              >
                EDULYKE DLA&nbsp;
                <span style={{ color: "rgb(233, 72, 0)", fontWeight: "bold" }}>
                  BIZNESU
                </span>
              </h2>
              <p className="hiw-teaser">
                Na program składa się kilkadziesiąt modułów zawierających treści
                wideo, quizy i materiały do pobrania. Co miesiąc użytkownicy
                platformy uzyskują dostęp do nowych treści.
              </p>
              <div id="chat" style={{ padding: "50px" }} className="tabcontent">
                <div className="item activeOffer wrap wrap-min-margin offerWrap1">
                  <div className="row a hiw-faq-carousel-wrapper">
                    <div>
                      <p className="hiw-faq-title">EDULYKE DLA BIZNESU</p>

                      <div className="hiw-faq-part middle-flex">
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">
                            20 KURSÓW WCHODZĄCYCH W SKŁAD PROGRAMU:
                          </p>
                          <p className="hiw-faq-part-info" style={{lineHeight: "1.9em"}}>
                            1. <span style={{fontWeight:"700"}}>Do dzieła</span> - jak wyznaczać i osiągać cel.
                            <br />
                            2. <span style={{fontWeight:"700"}}>First things first</span> - jak efektywnie ustalać priorytety.
                            <br />
                            3. <span style={{fontWeight:"700"}}>Razem</span> - jak pracować w zespole?
                            <br />
                            4. <span style={{fontWeight:"700"}}>Żeby mi się chciało…</span> - jak motywować siebie i zespół.
                            <br />
                            5. <span style={{fontWeight:"700"}}>Zrozum mnie</span> - jak konstruować wypowiedzi, żeby zostać dobrze zrozumianym.
                            <br />
                            6. <span style={{fontWeight:"700"}}>Dzień dobry!</span> - savoir vivre w miejscu pracy.
                            <br />
                            7. <span style={{fontWeight:"700"}}>Dam radę</span> - jak skutecznie wzmocnić swoją pewność siebie.
                            <br />
                            8. <span style={{fontWeight:"700"}}>Uspokój się</span> - jak to zrobić.
                            <br />
                            9. <span style={{fontWeight:"700"}}>Sprzężenie zwrotne</span> - jak radzić sobie z krytyką.
                            <br />
                            10. <span style={{fontWeight:"700"}}>Po prostu odmów.</span> Asertywność - tego można się nauczyć!
                            <br />
                            11. <span style={{fontWeight:"700"}}>Cierpliwości</span> - jak radzić sobie z trudnym klientem/współpracownikiem.
                            <br />
                            12. <span style={{fontWeight:"700"}}>Smacznego!</span> - jedz zdrowo i czuj się dobrze!
                            <br />
                            13. <span style={{fontWeight:"700"}}>Po prostu odpocznij</span> - jak odpoczywać efektywnie.
                            <br />
                            14. <span style={{fontWeight:"700"}}>Rusz się</span> - aktywność fizyczna dla każdego.
                            <br />
                            15. <span style={{fontWeight:"700"}}>Być wolnym</span> - profilaktyka uzależnień.
                            <br />
                            16. <span style={{fontWeight:"700"}}>Przyjrzyj się uważnie</span> - jak zastosować uważność dla lepszego życia.
                            <br />
                            17. <span style={{fontWeight:"700"}}>Przekonaj się!</span> - techniki negocjacyjne w codziennej pracy.
                            <br />
                            18. <span style={{fontWeight:"700"}}>Spokojnych snów</span> - jak to zrobić?
                            <br />
                            19. <span style={{fontWeight:"700"}}>Finanse osobiste</span> - oszczędzaj mądrze… i skutecznie!
                            <br />
                            20. <span style={{fontWeight:"700"}}>Wymyśl coś!</span> - rozwój kreatywności.
                          </p>
                        </div>
                      </div>
                      <div className="hiw-faq-part middle-flex">
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">WAŻNE INFORMACJE</p>
                          <p className="hiw-faq-part-info">
                            <ul style={{ paddingLeft: "15px" }}>
                              <li>program na platformie e - learningowej</li>
                              <li>zróżnicowany sposób przedstawienia treści</li>
                              <li>
                                materiały do pobrania pozwalają na utrwalenie
                                wiadomości
                              </li>
                              <li>
                                stworzony we współpracy ze specjalistami z danej
                                dziedziny - tylko sprawdzone informacje
                              </li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div className="hiw-faq-part middle-flex">
                        <div className="hiw-faq-part-text">
                          <p className="hiw-faq-part-title">CELE PROGRAMU</p>
                          <p className="hiw-faq-part-info">
                            <ul style={{ paddingLeft: "15px" }}>
                              <li>wieloobszarowe wsparcie pracowników</li>
                              <li>poprawa stanu zdrowia psychicznego</li>
                              <li>
                                wzmocnienie odporności psychicznej pracowników i
                                poczucia sensu poprzez wsparcie rozwoju
                                umiejętności miękkich.
                              </li>
                              <li>
                                zwiększenie zaangażowania i lojalności
                                pracowników.
                              </li>
                              <li>poprawa jakości pracy w firmie</li>
                              <li>zwiększenie wydajności pracy</li>
                            </ul>
                          </p>
                        </div>
                      </div>
                      <div style={{ textAlign: "center" }}>
                        <Link
                          className="offerBtn violet-offerBtn"
                          style={{
                            margin: "20px auto",
                            textDecoration: "none",
                          }}
                          to={`/kontakt`}
                        >
                          Zapytaj o indywidualną wycenę
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <LykeFooter />
      </>
    );
  }
}

export default OfferBusiness;
