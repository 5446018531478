import {Button, Col, Container, Row} from "reactstrap";
import React from "react";


const ThreeParts = () => {
    return (
        <>
        <div style={{minHeight: '100vh', backgroundColor: 'white', display: 'flex', justifyContent: 'center'}}>
            <div style={{marginTop: '100px'}}><h1 style={{textAlign: 'center'}}><span style={{color:'#ed5d01'}}>EDULYKE DLA BIZNESU</span> <br/>MA 3 CZĘŚCI</h1>
            <hr style={{color: 'orange', borderTop: '2px solid orange'}}/>
                <Container style={{marginTop:'-35px'}}>
                <Row>
                    <Col md="4">
                        <div className="info">
                            <h4 className="info-title partTitle">ZDROWIE <br />PSYCHICZNE</h4>
                            <div style={{textAlign:'left', fontSize: '18px'}} className={'ulListProgram'}>
                                <ul>
                                    <li>Jak wyznaczać i osiągać cel.</li>
                                    <li>Efektywne ustalanie priorytetów.</li>
                                    <li>Jak wzmocnić pewność siebie?</li>
                                    <li>Motywowanie siebie i zespołu.</li>
                                    <li>Zarządzanie własnym budżetem.</li>
                                    <li>Mindfulness</li>
                                    <li>Nauka asertywności.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                    <Col md="4">
                        <div className="info">
                            <h4 className="info-title partTitle communication">KOMUNIKACJA</h4>
                            <div style={{textAlign:'left', fontSize: '18px'}} className={'ulListProgram'}>
                                <ul>
                                    <li>Jak radzić sobie z krytyką?</li>
                                    <li>Konstruowanie wypowiedzi</li>
                                    <li>Praca zespołowa</li>
                                    <li>Praca z trudnym klientem</li>
                                    <li>Jak udzielać konstruktywnej krytyki?</li>
                                    <li>Savoir vivre w miejscu pracy.</li>
                                    <li>Techniki negocjacyjne.</li>
                                </ul>
                            </div>
                            </div>
                    </Col>
                    <Col md="4">
                        <div className="info">
                            <h4 className="info-title partTitle">AKTYWNOŚĆ <br />FIZYCZNA</h4>
                            <div style={{textAlign:'left', fontSize: '18px'}} className={'ulListProgram'}>
                                <ul>
                                    <li>Warsztaty odpoczywania.</li>
                                    <li>Zdrowe odżywianie.</li>
                                    <li>Optymalizacja miejsca pracy</li>
                                    <li>Aktywność fizyczna. </li>
                                    <li> Higiena snu.</li>
                                    <li>Profilaktyka uzależnień.</li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>
                </Container></div>

        </div>

    </>
    )
}

export default ThreeParts;