import React from "react";

import {
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";

const TestOpinions = [
  {
    src: "0",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placówki"
            className="img img-raised rounded"
            src={require("assets/img/korczak.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Szukaliśmy ciekawych rozwiązań, które wyróżnią się na rynku
            profilaktycznym i naszym zdaniem Lyke właśnie takie jest.
            Zdecydowaliśmy się na zakup pełnego programu dla klas IV-VIII szkół
            podstawowych m. in. ze względu na nowoczesne podejście do
            profilaktyki uzależnień. Wdrożyliśmy program EduLyke w naszej szkole
            w tym roku. Jesteśmy z niego zadowoleni i polecamy wszystkim
            podstawówkom szukającym ciekawego rozwiązania profilaktycznego.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa im. Janusza Korczaka w Koszelewach
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "1",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/salezjanie.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Programy profilaktyczne EduLyke wdrożyliśmy w Publicznej Szkole
            Podstawowej Towarzystwa Salezjańskiego im. Św. Jana Bosko w
            Aleksandrowie Kujawskim, w czerwcu 2022 roku. Zdecydowaliśmy się na
            ten program ze względu na nowoczesne podejście do profilaktyki
            uzależnień. W przypadku EduLyke główny nacisk położony jest na
            wzmacnianie umiejętności miękkich (takich jak asertywność czy
            pewność siebie) i to nas przekonało. Nie żałujemy, bo to właśnie te
            tematy wzbudzają największe zainteresowanie wśród uczniów.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Publiczna Szkoła Podstawowa Towarzystwa Salezjańskiego im. Św. Jana
            Bosko w Aleksandrowie Kujawskim
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "2",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/sochaczew.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Współpracujemy z firmą Lyke od bieżącego roku szkolnego i jesteśmy
            zadowoleni z efektów tej współpracy. Doceniamy wysoką jakość usługi,
            a przede wszystkim świeże spojrzenie na kwestie zdrowia i rozwoju
            dzieci. Szukaliśmy ciekawych rozwiązań, które wyróżnią się na rynku
            profilaktycznym i firma Lyke właśnie takie rozwiązania proponuje.
            Czekamy na kolejne ciekawe propozycje w tym zakresie.
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa nr 3 im. Bolesława Krzywoustego ul. Prezydenta
            Ryszarda Kaczorowskiego
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "3",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/szkola.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Jako placówka oświatowa jesteśmy zadowoleni ze współpracy z firmą
            Lyke. Ich usługi są na wysokim poziomie, a proponowane rozwiązania
            nowatorskie, ciekawe i wyróżniające się na rynku profilaktycznym.
            Zakres tematów bardzo szeroki - nie tylko profilaktyka uzależnień,
            ale też budowanie ważnych kompetencji miękkich. Polecamy współpracę
            z Lyke!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa w Smolicach
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "4",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/unicef.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Współpracujemy z Lyke już kolejny rok i jesteśmy zadowoleni ze
            względu na jakość usługi, ale przede wszystkim przez świeże
            spojrzenie na kwestie zdrowia i rozwoju dzieci. Szukaliśmy ciekawych
            rozwiązań, które wyróżnią się na rynku profilaktycznym i Lyke
            właśnie takie jest. Polecamy!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa nr 4 im. UNICEF w Obornikach
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "5",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/sochaczew1.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Dyrektor Szkoły Podstawowe nr 6 z Oddziałami Integracyjnymi w
            Sochaczewie wyraża zadowolenie ze współpracy z firmą Luke. Ich
            usługi są na wysokim poziomie, a obsługa pomocna. Z czystym
            sumieniem polecamy współpracę z Lyke!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa nr 6 z Oddziałami Integracyjnymi im. Króla
            Władysława Jagiełły w Sochaczewie
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
  {
    src: "6",
    altText: "",
    caption: "",
    content: (
      <Card className="card-testimonial card-plain">
        <div className="card-avatar">
          <img
            alt="logo placowki"
            className="img img-raised rounded"
            src={require("assets/img/szkola.jpg")}
          ></img>
        </div>
        <CardBody>
          <h5
            className="card-description"
            style={{ fontStyle: "italic", fontSize: "1.1em", color: "#3e3d3d" }}
          >
            Podjęliśmy współpracę z EduLyke ze względu na spojrzenie na kwestie
            zdrowia wśród dzieci i młodzieży. Profilaktyka EduLyke wyróżnia się
            na tle innych ofert. Polecamy !!!!
          </h5>
          <CardTitle tag="h6" style={{ marginTop: "30px" }}>
            Szkoła Podstawowa nr 2 im. Wojska Polskiego w Obornikach
          </CardTitle>
        </CardBody>
      </Card>
    ),
  },
];

export default TestOpinions;
