import { Button, Col, Container, NavLink, NavbarBrand, Row } from "reactstrap";
import React from "react";
import { Link } from "react-scroll";
import { Link as NaviLink } from "react-router-dom";

import logo from "../../assets/img/logo_heart.png";

const Landing = () => {
  return (
    <>
      <div className="landing">
        <div className="landingContainer">
          <div className={"logo-landing"} id="landing">
            <NavLink tag={NaviLink} to="/">
              <img src={logo} />{" "}
            </NavLink>
          </div>
          <div className="landingButtons">
            <Link to="whyB2b" smooth>
              <Button
                className="mr-3 space-btn"
                style={{ background: "transparent", fontWeight: "bold" }}
                size="lg"
              >
                DLACZEGO TEN PROGRAM
              </Button>
            </Link>{" "}
            <Link to="programForm" smooth>
              <Button
                className="mr-3 space-btn"
                style={{ background: "transparent", fontWeight: "bold" }}
                size="lg"
              >
                FORMA PROGRAMU
              </Button>
            </Link>
            <Link to="contact" smooth>
              <Button
                className="mr-3 space-btn"
                style={{ background: "transparent", fontWeight: "bold" }}
                size="lg"
              >
                KONTAKT
              </Button>
            </Link>
          </div>
        </div>
        <div className={"bg-overlay"}></div>
        <video
          style={{
            width: "100%",
            height: "100vh",
            position: "absolute",
            top: "0",
            zIndex: "-1",
            objectFit: "cover",
          }}
          autoPlay
          loop
          muted
          src="/video/landing.mp4"
        />
        <Container style={{ alignSelf: "flex-end" }}>
          <Row>
            <Col className="ml-auto" style={{ textAlign: "center" }} md="6">
              <h1 className="title" style={{ color: "#ed5d01" }}>
                Wyższy poziom środowiska pracy
              </h1>
              <div className="buttons">
                <Link to="offer" smooth>
                  {" "}
                  <Button
                    className="mr-3 space-btn"
                    style={{ background: "transparent", fontWeight: "bold" }}
                    size="lg"
                  >
                    {" "}
                    DOWIEDZ SIĘ WIĘCEJ
                  </Button>
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Landing;
