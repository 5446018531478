import React from "react";

import { Container, Row, Col } from "reactstrap";

import LykeFooter from "./LykeFooter";

const LykeWorld = () => {
  let pageHeader = React.createRef();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="wrapper">
        <div
          className="page-header page-header-small"
          style={{
            backgroundImage:
              "url(" + require("assets/img/worldcover.jpg") + ")",
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, .25)",
            }}
          ></div>
          <div className="page-header-image" ref={pageHeader}></div>
          <div className="content-center">
            <Container>
              <h1 className="title">Poznaj świat Lyke</h1>
            </Container>
          </div>
        </div>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">
                  Słowo <span style={{ color: "#FEBA02", fontWeight: "italic" }}>
                    Lykke
                  </span> oznacza po duńsku <span style={{ color: "#FEBA02", fontWeight: "italic" }}>
                    szczęście
                  </span>
                  .
                </h2>
                <h5 className="description">
                  Ale w tym słowie jest o wiele więcej. Lykke jest potężniejsze,
                  ponieważ to także styl życia, który ma na celu bycie
                  szczęśliwym. Tak po prostu.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/lykeworld.jpg") + ")",
                    }}
                  >
                    <p
                      className="blockquote blockquote-info"
                      style={{
                        color: "#e94800",
                        fontWeight: "500",
                        fontStyle: "italic",
                      }}
                    >
                      Szczęście nie jest ani dziełem przypadku, ani darem bogów.
                      Szczęście to coś, co każdy z nas musi wypracować dla
                      samego siebie.<br></br>
                      <br></br>
                      <small
                        style={{
                          color: "rgb(131, 128, 133)",
                          fontStyle: "normal",
                        }}
                      >
                        - Erich Fromm
                      </small>
                    </p>
                  </div>
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/lykeworld2.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/lykeworld1.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    Lykke to po prostu piękne i ważne postawy, po które warto
                    sięgać. I to właśnie robimy w Lyke.
                  </h3>
                  <p>
                    Filary skandynawskiej filozofii szczęścia opierają się na
                    uniwersalnych wartościach, dzięki czemu każdy może je
                    wcielić w życie. Nie ma tu wiedzy tajemnej, do której dostęp
                    zdobyli tylko Duńczycy. Świat Lyke nie skrywa w sobie
                    żadnych zawiłych tajemnic. Jego poznanie jest na
                    wyciągnięcie ręki. A to robisz przecież odruchowo i od
                    pierwszych chwil życia, prawda?
                  </p>
                  <p>
                    Wyciągasz rękę w kierunku, który cię ciekawi. Wyciągasz rękę
                    w kierunku, którego pragniesz. Wyciągasz rękę w kierunku,
                    który jest dla ciebie ważny.
                  </p>
                  <p>
                    Tak poznajesz, badasz, doświadczasz, bierzesz i dajesz, tak
                    komunikujesz. I w naszym świecie, Świecie Lyke, to wszystko,
                    czego potrzeba, aby poznawać i aby poznać - siebie, innych i
                    świat dookoła.
                  </p>
                  <p>
                    Filarami Świata Lyke są&nbsp;
                    <a className="lykeWorldEdu" href="https://www.edulyke.pl" rel="noopener noreferrer" target="_blank">
                      EduLyke
                    </a>,&nbsp;
                    <a
                      className="lykeWorldSenso"
                      href="https://www.sensolyke.pl"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      SensoLyke
                    </a> i&nbsp;
                    <span className="lykeWorldSmart">
                      SmartLyke
                    </span>
                    . EduLyke zbudowane jest na i dla edukacji. SensoLyke żyje
                    dla sfery psychofizycznej i dzięki niej. SmartLyke wspiera rozwój osobisty. Lyke stworzone jest
                    poprzez i w imię rozwoju i poznawania.
                  </p>
                  <p>
                    Założona w 2016 roku firma Lyke wyrosła z chęci stworzenia
                    możliwości wsparcia zdrowia fizycznego i psychicznego,
                    edukacji i rozwoju. Zespół tworzą ludzie z pasją do innych
                    ludzi i szukania rozwiązań ułatwiających życie dzieciom i
                    dorosłym.
                  </p>
                  <p>
                    Szukając tych wartości tutaj, wyciągasz rękę w dobrym
                    kierunku.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
      <LykeFooter />
    </>
  );
};

export default LykeWorld;
